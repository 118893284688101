import './App.css';
import './Square.js'
import Square from './Square.js';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p className="Console-text">yossigor</p>
        <Square value="1"></Square>
        <Square value="2"></Square>
        <Square value="3"></Square>
        <Square value="4"></Square>
        <Square value="5"></Square>
        <Square value="6"></Square>
        <Square value="7"></Square>
        <Square value="8"></Square>
        <Square value="9"></Square>
      </header>
    </div>
  );
}

export default App;
